<template>
  <div class="content-bd" :class="ClassLayout">
    <div class="content-left" :class="{ 'fadeInLeft animated': $store.state.animationClass }">
      <section class="practice">
        <header class="section-hd">
          <span class="section-title-l"></span>
          <h2 class="section-title">工作经验</h2>
          <span class="section-title-r"></span>
        </header>
        <div class="section-bd">
          <div class="item">
            <header class="item-hd">
              <div class="item-hd-box">
                <div class="item-hd-box-img" @click="showBox(4)">
                  <img :src="BoxList[4].imgUrl" alt="">
                </div>
                <div>
                  <h3 class="item-name">进芯电子</h3>
                  <span class="item-time">2023-07-10 至 至今</span>
                </div>
              </div>
              <a class="btn item-more" href="" title="点击查看->进芯电子">开发</a>
            </header>
            <ol :class="BoxList[4].class == true ? 'NOBox' : ''" class="item-project">
              <li class="project-1">
                <h3 class="project-name">芯片设计平台前端开发</h3>
                <ol>
                  <li class="project-2">
                    <div class="item-bd">
                      <h3 class="project-name-2">指令集信息录入界面开发</h3>
                      <div class="div-item">
                        <h3 class="project-des">【工作职责】</h3>
                        <ul class="section-content">
                          <li>依据技术手册对指令的特征进行分析，对指令集信息录入页面进行设计和实现</li>
                          <li>完成其余工作职责</li>
                        </ul>
                      </div>
                      
                      <h3 class="project-name">模拟器后端开发</h3>
                      <div class="div-item">
                        <h3 class="project-des">【工作职责】</h3>
                        <ul class="section-content">
                          <li>理解C28x外设手册和指令集编程手册,模拟芯片的周期流水线行为并进行代码实现</li>
                          <li>完善与前端的数据交互</li>
                        </ul>
                      </div>
                    </div>

                  </li>

                </ol>
              </li>
            </ol>
          </div>

        </div>

      </section>
      <section class="practice">
        <header class="section-hd">
          <span class="section-title-l"></span>
          <h2 class="section-title">项目经验</h2>
          <span class="section-title-r"></span>
        </header>
        <div class="section-bd">


          <!--经历-->
          <div class="item">
            <header class="item-hd">

              <div class="item-hd-box">
                <div class="item-hd-box-img" @click="showBox(1)">
                  <img :src="BoxList[1].imgUrl" alt="">
                </div>
                <div>
                  <h3 class="item-name">C28xCAS周期精确模拟器</h3>
                  <span class="item-time">2023.10 至 2024.5 </span>
                </div>
              </div>
              
            </header>
            <ol :class="BoxList[1].class == true ? 'NOBox' : ''" class="item-project">
              <li class="project-1">
                <h3 class="project-name">周期精确模拟器</h3>
                <ol>
                  <li class="project-2">
                    <div class="item-bd">
                      <h3 class="project-name-2">周期精确模拟器</h3>
                      <div class="div-item">
                        <h3 class="project-des">【项目简介】</h3>
                        <p class="item-project-des">
                          依据TI技术手册对C28x芯片的流水线部分进行建模，达到指令的行为级模拟，周期数符合手册
                        </p>
                      </div>
                      <div class="div-item">
                        <h3 class="project-des">【工作职责/技术栈分析】</h3>
                        <ul class="section-content">
                          <li>流水线分析及程序实现</li>
                          <li>采用Rust语言进行程序开发，包括对hex格式的可执行程序整理，取址12，译码12，读/写数据，执行，回写八个阶段
                          </li>
                          <li>与前端和解析器端的数据交互</li>
                          <li>采用rocket框架实现周期数据与前端交互，采用web socket框架与解析器端进行数据交互</li>
                        </ul>
                      </div>
                      <div class="div-item">
                        <h3 class="project-des">【收获】</h3>
                        <ul class="section-content">
                          <li>对复杂指令集CISC有了更深的认识</li>
                          <li>完成了开发要求</li>
                          <li>对不同编程语言的熟练程度进一步加深</li>
                        </ul>
                      </div>
                    </div>

                  </li>

                </ol>
              </li>
            </ol>
          </div>

          <!--经历:张旅-->
          <div class="item">
            <header class="item-hd">

              <div class="item-hd-box">
                <div class="item-hd-box-img" @click="showBox(2)">
                  <img :src="BoxList[2].imgUrl" alt="">
                </div>
                <div>
                  <h3 class="item-name">CoreDSL-Chisel编译器</h3>
                  <span class="item-time">2024-06 至今</span>
                </div>
              </div>
            </header>
            <ol :class="BoxList[2].class == true ? 'NOBox' : ''" class="item-project">
              <li class="project-1">
                
                <ol>
                  <li class="project-2">
                    <div class="item-bd">
                      <h3 class="project-name-2">程序开发</h3>
                      <div class="div-item">
                        <h3 class="project-des">【项目简介】</h3>
                        <p class="item-project-des">
                          此项目将C28x指令的CoreDSL行为级描述转换为Chisel代码，进而生成Verilog电路进行芯片的正向设计
                        </p>
                      </div>
                      <div class="div-item">
                        <h3 class="project-des">【工作职责】</h3>
                        <ul class="section-content">
                          <li>采用antlr4及Xtext，Xtend进行模板的设计</li>
                          <li>主要负责语法及模板的设计，以及生成Chisel的仿真工作</li>
                          <li>使用Scala，及领域特定语言Chisel，Xtext作为主要工具</li>
                        </ul>
                      </div>
                      <div class="div-item">
                        <h3 class="project-des">【收获】</h3>
                        <ul class="section-content">
                          <li>加深了对dsl的理解</li>
                          <li>更熟练的进行规则及语法开发</li>
                          <li>采用生成式开发技术</li>
                        </ul>
                      </div>
                    </div>

                  </li>

                </ol>
              </li>
            </ol>
          </div>

        </div>

      </section>
    </div>
    <div class="content-right" :class="{ 'fadeInRight animated': $store.state.animationClass }">
      <section class="skill">
        <header class="section-hd">
          <span class="section-title-l"></span>
          <h2 class="section-title">技能</h2>
          <span class="section-title-r"></span>
        </header>
        <div class="section-bd">

          <!--技能:WEB后端开发-->
          <div class="item">
            <header class="item-hd">
              <span class="item-time">MATLAB</span>
              <a class="btn item-more">掌握</a>
            </header>
            <div class="item-bd">
              <ul class="section-content">
                <li>掌握对优化相关算法最速下降,梯度下降等的实现</li>
                <li>掌握CVX,Simulink等相关仿真模块的使用</li>
                
                <!-- <li>有金融交易、区块链及数字货币行业独立工作经验</li> -->
              </ul>
            </div>
          </div>
          <div class="item">
            <header class="item-hd">
              <span class="item-time">python后端开发</span>
              <a class="btn item-more">熟练掌握</a>
            </header>
            <div class="item-bd">
              <ul class="section-content">
                <li>第一版模拟器前四阶段实现</li>
                <li>掌握拟牛顿法，模拟退火等相关算法的实现</li>
                
                <!-- <li>有金融交易、区块链及数字货币行业独立工作经验</li> -->
              </ul>
            </div>
          </div>

          <!--服务器|架构-->
          <div class="item">
            <header class="item-hd">
              <span class="item-time">服务器|架构</span>
              <a class="btn item-more">掌握</a>
            </header>
            <div class="item-bd">
              <ul class="section-content">
                <li>熟练掌握Nginx、Tomcat、Apache服务器使用</li>
                <li>熟练掌握常用软件（MySQL、Redis、Rabbit MQ、Nacos、xxl-job、Elasticsearch等）在服务器的搭建与使用</li>
                <li>熟练使用Centos(Linux)云服务器并掌握线上环境和应用的部署</li>
                <li>了解Docker使用及Docker应用部署</li>
                <li>熟悉微服务、高并发、高可用集群服务端架构</li>
                <li>熟练掌握Git、Github、gradle、maven等工具使用</li>
              </ul>
            </div>
          </div>

          <!--WEB前端开发-->
          <div class="item">
            <header class="item-hd">
              <span class="item-time">WEB前端开发</span>
              <a class="btn item-more">掌握</a>
            </header>
            <div class="item-bd">
              <ul class="section-content">
                <li>熟悉前端技术HTML、VUE、JS、CSS有实际项目中的前端开发、维护经验</li>
                <li>掌握网页重构所需的CSS、SCSS</li>
                <li>熟知JS基本语法与知识，熟悉JS语法</li>
              </ul>
            </div>
          </div>

          <!--React-->
          <div class="item">
            <header class="item-hd">
              <span class="item-time">Rust</span>
              <a class="btn item-more">熟练掌握</a>
            </header>
            <div class="item-bd">
              <ul class="section-content">
                <li>熟悉相关开发逻辑，生命周期，线程，泛型等使用。</li>
                
              </ul>
            </div>
          </div>
          <div class="item">
            <header class="item-hd">
              <span class="item-time">建模及相关DSL</span>
              <a class="btn item-more">掌握</a>
            </header>
            <div class="item-bd">
              <ul class="section-content">
                <li>熟悉UML,SysML等建模DSL的使用,熟练使用建模工具itemisCreate</li>
                <li>理解敏捷开发,极限编程XP思想</li>
              </ul>
            </div>
          </div>


          <!--独立开发-->
          <div class="item">
            <header class="item-hd">
              <span class="item-time">独立开发</span>
            </header>
            <div class="item-bd">
              <ul class="section-content">
                <li>有独自主导并负责的项目开发经验，在项目中可进行独立开发</li>
                <li>有良好的编码习惯与编程思想，学习能力强，可快速学习新技术，对新技术、好技术热爱</li>
                <li>工作稳定性高，对公司负责，有一定的项目管理经验</li>
              </ul>
            </div>
          </div>

       
        </div>
      </section>

      <!--奖项/证书-->
      <section class="prize">
        <header class="section-hd">
          <span class="section-title-l"></span>
          <h2 class="section-title">文章/证书</h2>
          <span class="section-title-r"></span>
        </header>
        <div class="section-bd">

          <!--技能:WEB后端开发-->
          <div class="item">
            <header class="item-hd">
              <span class="item-time">发表文章</span>
            </header>
            <div class="item-bd">
              <ul class="section-content">
                <li>一种新的MBFGS方法[J].应用数学进展,2022,11(8):5981-5985</li>
              
              </ul>
            </div>

            <header class="item-hd">
              <span class="item-time">获得证书</span>
            </header>
            <div class="item-bd">
              <ul class="section-content">
                <li>大学英语四级</li>
                <li>普通话二级甲等</li>
                <li>2023年长沙理工大学研究生学业奖学金二等</li>
              </ul>
            </div>
          </div>
        </div>
      </section>

      <section class="prize">
        <header class="section-hd">
          <span class="section-title-l"></span>
          <h2 class="section-title">教育经历</h2>
          <span class="section-title-r"></span>
        </header>
        <div class="section-bd">

          <!--技能:WEB后端开发-->
          <div class="item">
            <header class="item-hd">
              <span class="item-time">东华理工大学(本科)</span>
            </header>
            <div class="item-bd">
              <ul class="section-content">
                <li>2015.9~2019.6</li>
              
              </ul>
            </div>

            <header class="item-hd">
              <span class="item-time">长沙理工大学(硕士)</span>
            </header>
            <div class="item-bd">
              <ul class="section-content">
                <li>2020.9~2023.6</li>
              </ul>
            </div>
          </div>
        </div>
      </section>

  

    </div>
  </div>
</template>

<script>
import $ from 'jquery'
export default {
  name: 'Content',
  components: {},
  data() {
    return {
      msg: 'Welcome to Your Vue.js App',
      BoxList: [
        {
          class: false,
          imgUrl: require('../../assets/add-circle.png')
        },
        {
          class: false,
          imgUrl: require('../../assets/add-circle.png')
        },
        {
          class: false,
          imgUrl: require('../../assets/add-circle.png')
        },
        {
          class: false,
          imgUrl: require('../../assets/add-circle.png')
        },
        {
          class: false,
          imgUrl: require('../../assets/add-circle.png')
        },
      ],
      ClassLayout: '',

    }
  },
  watch: {
    '$store.state.changeLayoutClass': {
      handler(newVal) {
        this.ClassLayout = newVal ? 'HLayout' : '';
      }
    }
  },
  methods: {
    showBox(flag) {
      if (this.BoxList[flag].class) {
        this.BoxList[flag].class = false;
        this.BoxList[flag].imgUrl = require('../../assets/add-circle.png')
      } else {
        this.BoxList[flag].class = true;
        this.BoxList[flag].imgUrl = require('../../assets/minus-circle.png')
      }
    }
  },
  mounted() {
    // $(document).ready(function () {
    //   if ($('ol:first').css('list-style-type') == 'none') { /* For IE6/7 only. */
    //     $('ol ol').each(function (i, ol) {
    //       ol = $(ol);
    //       var level1 = ol.closest('li').index() + 1;
    //       ol.children('li').each(function (i, li) {
    //         li = $(li);
    //         var level2 = level1 + '.' + (li.index() + 1);
    //         li.prepend('<span>' + level2 + '</span>');
    //       });
    //     });
    //   }
    // });
  }
}
</script>
<style lang="less" scoped>
.HLayout {
  flex-direction: column !important;

  .content-left {
    border-right: none;
  }

  .section-hd {
    height: 50px;

    .section-title {
      font-size: 25px;
    }
  }


}
</style>
