<template>
    <div class="head" v-if="isHead">
        <div>
            <p class="last-modified">最后更新于2023年7月</p>
        </div>
        <div class="btn" @click="Changelayout">
            改变布局
        </div>

        <div class="btn" @click="getPWA">
            添加到桌面
        </div>

        <div>
            <span class="download-pdf" @click="getDowoldPDF">下载 PDF</span>
        </div>

    </div>
    <div class="win" @click="getPWA" v-show="isPWA" v-else>
        <svg t="1695010051839" class="icon" viewBox="0 0 1024 1024" version="1.1" xmlns="http://www.w3.org/2000/svg"
            p-id="12045" width="16" height="16">
            <path
                d="M760.685714 629.76l-281.6 281.6L197.485714 629.76h168.96V460.8h225.28v168.96h168.96z m56.32-281.6a28.525714 28.525714 0 1 1-28.525714-28.525714c16.091429 0 28.525714 12.434286 28.525714 28.525714z m-648.045714 70.217143a13.897143 13.897143 0 1 1-27.794286 0V277.211429a13.897143 13.897143 0 1 1 27.794286 0v141.165714z m704.365714 98.742857V178.468571c0-31.451429-24.868571-56.32-56.32-56.32H141.165714c-31.451429 0-56.32 24.868571-56.32 56.32v337.92c0 31.451429 24.868571 56.32 56.32 56.32h168.96v-55.588571H225.28V178.468571h479.085714v337.92h-56.32v56.32h168.96c31.451429 0.731429 56.32-24.868571 56.32-55.588571z"
                p-id="12046"></path>
        </svg>
        <span>添加到桌面</span>

    </div>
</template>

<script>
import { Message } from "element-ui";
import { getPdf } from '@/uilt/getPDF'
export default {
    name: 'Head',
    data() {
        return {
            isHead: true,
            isPWA: false,
        }
    },
    methods: {
        getDowoldPDF() {
            this.$store.state.animationClass = false
            const target = document.getElementById('contentPDF')
            this.outPutPdfFn('郑轶天 - 编译器助理工程师', target, 'item')
        },
        outPutPdfFn(title, target, itemClass) {
            const vm = this
            const A4_WIDTH = 592.28
            const A4_HEIGHT = 841.89

            vm.$nextTick(() => {
                // dom的id。

                const pageHeight = target.scrollWidth / A4_WIDTH * A4_HEIGHT
                // 获取分割dom，此处为class类名为item的dom
                const lableListID = document.getElementsByClassName(itemClass)
                // 进行分割操作，当dom内容已超出a4的高度，则将该dom前插入一个空dom，把他挤下去，分割
                for (let i = 0; i < lableListID.length; i++) {
                    const multiple = Math.ceil((lableListID[i].offsetTop + lableListID[i].offsetHeight) / pageHeight)
                    if (this.isSplit(lableListID, i, multiple * pageHeight)) {
                        const divParent = lableListID[i].parentNode // 获取该div的父节点
                        const newNode = document.createElement('div')
                        newNode.className = 'emptyDiv'
                        newNode.id = 'emptyDiv'
                        newNode.style.background = '#ffffff'
                        const _H = multiple * pageHeight - (lableListID[i].offsetTop + lableListID[i].offsetHeight)
                        newNode.style.height = _H + 30 + 'px'
                        newNode.style.width = '100%'
                        const next = lableListID[i].nextSibling // 获取div的下一个兄弟节点
                        // 判断兄弟节点是否存在
                        console.log(next)
                        if (next) {
                            // 存在则将新节点插入到div的下一个兄弟节点之前，即div之后
                            divParent.insertBefore(newNode, next)
                        } else {
                            // 不存在则直接添加到最后,appendChild默认添加到divParent的最后
                            divParent.appendChild(newNode)
                        }
                    }
                }
                // 传入title和dom标签，此处是 #content
                // 异步函数，导出成功后处理交互
                getPdf(title, target, 'emptyDiv').then(() => {
                    // 自定义等待动画关闭
                    this.getMessage();
                })
                this.$nextTick(() => {
                    const emptyDom = document.getElementById('emptyDiv')
                    emptyDom.remove()
                })


            })
        },
        getMessage() {
            // 自定义等待动画关闭
            Message({
                type: 'success',
                message: '导出成功'
            })
            this.detailSHow = false
        },
        isSplit(nodes, index, pageHeight) {
            // 计算当前这块dom是否跨越了a4大小，以此分割
            if (nodes[index].offsetTop + nodes[index].offsetHeight < pageHeight && nodes[index + 1] && nodes[index + 1].offsetTop + nodes[index + 1].offsetHeight > pageHeight) {
                return true
            }
            return false
        },
        Changelayout() {

            this.$store.state.changeLayoutClass = this.$store.state.changeLayoutClass ? false : true

        },
        _isMobile() {
            // alert(window.matchMedia('(display-mode: standalone)').matches)
            this.isPWA = !window.matchMedia('(display-mode: standalone)').matches
            return /phone|pad|pod|iPhone|iPod|ios|iPad|Android|Mobile|BlackBerry|IEMobile|MQQBrowser|JUC|Fennec|wOSBrowser|BrowserNG|WebOS|Symbian|Windows Phone|Macintosh/i.test(navigator.userAgent);
        },
        getPWA() {
            let deferredPrompt = this.$store.state.deferredPrompt
            if (deferredPrompt !== undefined) {
                deferredPrompt.prompt();

                deferredPrompt.userChoice.then((choiceResult) => {
                    if (choiceResult.outcome === 'accepted') {
                        console.log('用户已安装 PWA');
                    } else {
                        console.log('用户取消安装 PWA');
                    }
                    deferredPrompt = null;
                });
            }

        }
    },
    mounted() {

        //判断是否可以下载pwa
        window.addEventListener('beforeinstallprompt', (e) => {
            e.preventDefault();
            // 在这里添加一个按钮或其他用户界面元素，触发安装事件
            this.$store.state.deferredPrompt = e;

        });
        this.isHead = !this._isMobile()
    },
    computed: {},
}
</script>
<style scoped>
.btn {
    text-decoration: none;
    transform: translate(-50%, -50%);
    font-size: 10px;
    background: linear-gradient(90deg, #03a9f4, #f441a5, #ffeb3b, #03a9f3);
    background-size: 400%;
    width: 100px;
    height: 30px;
    color: #ffffff;
    text-align: center;
    margin-top: 10px;
    border-radius: 50px;
    z-index: 1;
    display: flex;
    justify-content: center;
    align-items: center;
}

.btn::before {
    content: "";
    position: absolute;
    left: -5px;
    right: -5px;
    top: -5px;
    bottom: -5px;
    background: linear-gradient(90deg, #03a9f4, #f441a5, #ffeb3b, #03a9f3);
    background-size: 400%;
    border-radius: 50px;
    filter: blur(20px);
    z-index: -1;
}

.btn:hover::before {
    animation: sun 8s infinite;
}

.btn:hover {
    animation: sun 8s infinite;
}

@keyframes sun {
    100% {
        background-position: -400% 0;
    }
}

.win {
    width: 100%;
    height: 50px;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 15px;
    font-weight: 800;
    position: fixed;
    top: 0;
    z-index: 99;

    background: rgba(76, 139, 217, 00.7);
    border-radius: 0 0 10px 10px;

    span {
        margin-left: 10px;
    }
}
</style>


