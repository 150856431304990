<template>
  <footer class="github-footer">
    <a class="footer-link" href="https://github.com/GuoyiZhang/078-Vue-resumeVue">项目库托管在Github上，欢迎star~</a>
  </footer>
</template>
<script>

    export default {
        name: 'foot',
        data() {
            return {
            }
        },
        computed: {
        },
        mounted() {
        },
    }
</script>
