<template>
  <header class="content-hd">
    <div class="img-box" :class="{ 'bounceIn animated': $store.state.animationClass }">
      <img src="../../assets/zjimg.jpg" alt="">
    </div>
    <div class="conten-box">
      <section class="title">
        <div class="name" :class="{ 'zoomInDown animated': $store.state.animationClass }">
          <h1>郑轶天</h1>
        </div>
        <div class="job" :class="{ 'zoomInDown animated': $store.state.animationClass }">
          <h2>编译器助理工程师</h2>
        </div>
      </section>
      <section class="info " :class="{ 'slideInLeft animated': $store.state.animationClass }">
        <ul>
          <li>男 / 1997.12</li>
          <li>长沙理工大学</li>
          <li>数学</li>
        </ul>
      </section>
      <section class="contact" :class="{ 'slideInRight animated': $store.state.animationClass }">
        <ul>
          <li>
            <a href="mailto:952790996@qq.com" target="_blank">
              <span class="contact-link">15717502345@163.com</span>
              <span class="iconfont">
                <svg width="16px" height="16px" viewBox="0 0 1024 1024">
                  <path
                    d="M903.808 239.04l-818.304 0 0 545.92 852.992 0 0-545.92-34.688 0zM869.664 273.152l-329.536 329.536c-15.04 15.04-41.248 15.04-56.288 0l-329.6-329.536 715.392 0zM119.616 286.752l221.536 221.504-221.536 221.536 0-443.072zM146.848 750.848l218.464-218.432 94.432 94.4c13.952 13.952 32.512 21.664 52.256 21.664s38.304-7.712 52.256-21.664l94.432-94.432 218.432 218.432-730.304 0zM904.384 729.824l-221.536-221.568 221.536-221.568 0 443.136z">
                  </path>
                </svg>
              </span>
            </a>
          </li>
          <li>
            <a href="tel:15717502345" target="_blank">
              <span class="contact-link">15717502345</span>
              <span class="iconfont">
                <svg width="16px" height="16px" viewBox="0 0 1024 1024">
                  <path
                    d="M256 1024l512 0c26.464 0 48-21.536 48-48l0-928c0-26.464-21.536-48-48-48l-512 0c-26.464 0-48 21.536-48 48l0 928c0 26.464 21.536 48 48 48zM240 48c0-8.832 7.168-16 16-16l512 0c8.832 0 16 7.168 16 16l0 928c0 8.832-7.168 16-16 16l-512 0c-8.832 0-16-7.168-16-16l0-928zM288 864l448 0c8.832 0 16-7.168 16-16l0-672c0-8.832-7.168-16-16-16l-448 0c-8.832 0-16 7.168-16 16l0 672c0 8.832 7.168 16 16 16zM304 192l416 0 0 640-416 0 0-640zM496 928c0 17.664 14.336 32 32 32s32-14.336 32-32c0-17.664-14.336-32-32-32-17.664 0-32 14.336-32 32zM448 128l128 0c8.832 0 16-7.168 16-16s-7.168-16-16-16l-128 0c-8.832 0-16 7.168-16 16s7.168 16 16 16z">
                  </path>
                </svg>
              </span>
            </a>
          </li>
        </ul>
      </section>
    </div>
  </header>
</template>

<script>
export default {
  name: 'BaseInfo',
  data() {
    return {
    }
  },
  mounted() {
  },
  computed: {},
}
</script>

